import React,{useEffect, useState} from 'react'
import { useForm, Controller } from "react-hook-form";
// import {CKEditor} from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from 'ckeditor4-react';
import { useHistory } from "react-router-dom";
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import ImagePreview from '../ImagePreview/ImagePreview';
import useRequest from "../../hooks/useRequest";
import { toast } from "react-toastify";

const Edit = (props) => {
  const { id:recordId } = props.match.params;
  const history = useHistory();
  const { response, request }                                                         =   useRequest();
  const { response:responseUpdate, request:requestUpdate }                            =   useRequest();
  const {request:requestCities, response: responseCities}                             =   useRequest();

  const [cities, setCities]                                                           =   useState([]);
  const [touchedError, setTouchedError]                                               =   useState(false);
  const [displayImage, setDisplayImage]                                               =   useState();
  const [image, setImage]                                                             =   useState();
  const [selectedCity, setSelectedCity]                                               =   useState();
  const [ckcontent, setCkContent]                                                     =   useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors
} = useForm();

  useEffect(() => {
    document.title = "Edit City Attraction - Sportyfy";
    requestCities("GET","admin/vendor/getCity")
    register("description", {
      required: true
    })
    request("GET",`admin/vendor/getEventAttractionDetail?dataId=${recordId}`)
},[recordId])

useEffect(() => {
  if(responseCities){
    if(responseCities.status && responseCities.data){
      setCities(responseCities.data);
    }
  }
},[responseCities])
useEffect(() => {
  if(response){
    if(response.status && response.data){
      setValue("city",response.data.cityId);
      setValue("title",response.data.title);
      setValue("description", response.data.description);
      setImage(response.data?.image[0]);
      setSelectedCity(response.data.cityId);
      setCkContent(response.data.description)
    }
  }
},[response])



  const onSubmit = (data) => {
    const {city, title, description}   =   data;
    const formData = new FormData();
    formData.append("cityId", city);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("type", "city_attraction");
    formData.append("dataId", recordId);

    if (displayImage) {
        if (data.image) {
          formData.append("image", data.image);
        }
    } else {
        if(image == null || image == 'undefined'){
          formData.append("oldImage", '');
        }else{
          formData.append("oldImage", image);
        }
    }

    requestUpdate("POST", "admin/vendor/editCityEventAttraction",formData );
  }

  useEffect(() => {
    if (responseUpdate) {
        if(responseUpdate.status && responseUpdate.data){
            toast.success(responseUpdate.message);
            history.push("/city-attractions")
        }
    }
}, [responseUpdate]);

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setError("image", "");
      setValue("image", event.target.files[0]);
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
};

const handleRemoveImage = () => {
  if(document.getElementById("image")){
      document.getElementById("image").value = "";
  }
    setDisplayImage(null);
    setValue("image", null);
    setImage("");
};


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
        <Breadcrumb
                  title={"Edit"}
                  links={[
                      { to: "/dashboard", name: "Home" },
                      { to: "/city-attractions", name: "City Attractions" },
                  ]}
              />
           
        </div>
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Edit City Attraction</h6>
                </div>
                <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="city">City<span className="field-required">*</span></label>
                                <div className="input-group">
                                  <select {...register("city", {
                                        required: true,
                                    })}  className={`form-control ${
                                        touchedError ? ( errors.city ? "is-invalid" : "is-valid" ): "" 
                                      }`}  id="city">
                                        <option value={""}>Select</option>
                                        {cities && cities.length>0 && cities.map(obj => (
                                          <option key={obj._id} selected={obj._id == selectedCity ? "selected":""}value={obj._id}>{obj.name}</option>
                                        ))}
                                    </select>
                                    
                                      {errors.city?.type === "required" && (
                                        <div className="invalid-field">
                                            The city field is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Title<span className="field-required">*</span></label>
                                <div className="input-group">
                                    <input type="text" id="title" placeholder="Title" 
                                        className={`form-control ${
                                            touchedError ? ( errors.title ? "is-invalid" : "is-valid" ): "" 
                                        }`}
                                        {...register("title", {
                                            required: true,
                                        })}/>
                                         {errors.title?.type === "required" && (
                                            <div className="invalid-field">
                                                The title field is required.
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="description">Description<span className="field-required">*</span></label>
                                <div className="input-group">
                                {ckcontent && ( 
                                      <CKEditor
                                          initData={getValues("description")}
                                          config={{
                                            extraAllowedContent:
                                              "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                                            allowedContent: true,
                                            protectedSource: [/<i[^>]*><\/i>/g],
                                            // removeEmpty: { i: false },
                                          }}
                                          onChange={({ editor }) => {
                                            const data = editor.getData();
                                            setValue("description", data);
                                            clearErrors("description");
                                          }}
                                        />
                                      )}
                                        
                                      {errors?.description?.type === "required" && (
                                            <div className="invalid-field">
                                                The description field is required.
                                            </div>
                                        )}
                                </div>
                            </div>
                           
                            <div className="col-md-12 mb-3">
                                <label htmlFor="image"> Image</label>
                                <div className="custom-file">
                                    <Controller
                                        control={control}
                                        name="image"
                                        render={({ field: { onChange, value, ref } }) => (
                                        <input
                                            type="file"
                                            id="image"
                                            onChange={handleImage}
                                            style={{ display: "none" }}
                                            inputRef={ref}
                                            accept="image/*"
                                            className="custom-file-input"
                                        />
                                    )}/>
                                    <label className="custom-file-label" htmlFor="image">Images</label>
                                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                                </div>
                            </div>

                            
                            <button className="btn btn-primary mt-4 d-block w-100" onClick={() => setTouchedError(true)} type="submit">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        {(displayImage || image) && (
        <div className="col-xl-6 col-md-12">
            <div className="row">
                <div className="col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-header">
                            <h6>Image </h6>
                        </div>
                        <div className="ms-panel-body">
                            <ImagePreview displayImage={displayImage} image={image}/>
                        </div>
                     
                        <div className="ms-panel-header ">
                            <label className="btn btn-primary mx-2" onClick={handleRemoveImage} >Remove</label>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        )}
    </div>
    </div>
  )
}

export default Edit
