import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const AllOutlet = (props) => {
  const { id: vendorId } = props.match.params;
  const history = useHistory();
  const { request: request, response: response } = useRequest();
  const [outletList, setOutletList] = useState([]);
  const [showCommisionModal, setShowCommisionModal] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [rewardStatus, setRewardStatus] = useState();

  useEffect(() => {
    if (!vendorId) {
      history.push("/dashboard");
    }
    request("GET", `admin/vendor/getOutlets?vendorId=${vendorId}`);
  }, [vendorId]);

  useEffect(() => {
    if (response) {
      if (response.status && response.data) {
        setOutletList(response.data);
      }
    }
  }, [response]);

  const handleShowCommisionModal = (commision) => {
    setSliderValue(commision);
    setShowCommisionModal(!showCommisionModal);
  };

  const handleShowRewardModal = (rewardStatus) => {
    setRewardStatus(rewardStatus);
    setShowRewardModal(!showRewardModal);
  };

  const renderRewardModal = () => {
    return (
      <Modal
        show={setShowRewardModal}
        onHide={handleShowRewardModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reward point partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          5% reward point will be offered as reward loyalty program
          <br />
          <br />
          <b>Status:</b> {rewardStatus}
        </Modal.Body>
      </Modal>
    );
  };
  const renderCommisionModal = (outletId) => {
    return (
      <Modal
        show={setShowCommisionModal}
        onHide={handleShowCommisionModal}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Commision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "25px" }}>
            <Col md={6}>
              <label htmlFor="foodLicence" className="form-label customLabel">
                Commision
              </label>
              <Slider
                orientation="horizontal"
                min={0}
                max={100}
                value={sliderValue}
                onChange={(val) => setSliderValue(val)}
              />
              {sliderValue}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowCommisionModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Outlets"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/vendors", name: "Vendors" },
            ]}
          />
          <div className="row">
            {outletList &&
              outletList.length > 0 &&
              outletList.map((item, i) => (
                <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                  <div className="ms-card">
                    <div className="ms-card-img">
                      {item && item.image && (
                        <img
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                          src={item.image}
                          alt="card_img"
                        />
                      )}
                    </div>
                    <div className="ms-card-body">
                      <div className="new">
                        <h6 className="mb-0">{item.outletName} </h6>
                        <h6 className="ms-text-primary mb-0">{i + 1}</h6>
                      </div>
                      <div classNamtotalCuisinee="new meta">
                        {/* <p>Qty:{item.qyt} </p> */}
                        {/* <span className={item.statuscls}>{item.status}</span> */}
                      </div>
                      <p>{item.description}</p>
                      <div className="new mb-0 mt-3">
                        <Link
                          // to={`/vendor/menus/${item.outletId}`}
                          to={{
                            pathname: `/vendor/view-outlet/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                        >
                          View
                        </Link>

                        <Link
                          className={
                            item?.menuActive
                              ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                              : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"
                          }
                          // to={`/vendor/menus/${item.outletId}`}
                          to={{
                            pathname: `/vendor/menus/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                        >
                          Menu
                        </Link>
                      </div>
                      <div className="new mt-4">
                        <Link
                          to={{
                            pathname: `/vendor/orders/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                        >
                          Orders
                        </Link>
                        <button
                          type="button"
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() =>
                            handleShowCommisionModal(item.commissionPoint)
                          }
                        >
                          Commision
                        </button>
                      </div>
                      <div className="new mt-4">
                        <button
                          type="button"
                          className={item?.rewardActive  ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set" :"btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"}

                          onClick={() => handleShowRewardModal(item.isReward)}
                        >
                          Reward point
                        </button>
                        <Link
                          to={{
                            pathname: `/vendor/discounts/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className={
                            item?.discountActive
                              ? "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                              : "btn grid-btn mt-0 btn-sm steps-btn btn-btn-set red-color-btn"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        >
                          Discount
                        </Link>

                        {showRewardModal && renderRewardModal()}
                        {showCommisionModal &&
                          renderCommisionModal(item.outletId)}
                      </div>
                      <div className="new mt-4">
                        <Link
                          to={{
                            pathname: `/vendor/promotions/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                        >
                          Promotion
                        </Link>

                        <Link
                          to={{
                            pathname: `/vendor/reports/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                        >
                          Report
                        </Link>
                      </div>
                      <div className="new mt-4">
                        <Link
                          to={{
                            pathname: `/vendor/trends/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn"
                        >
                          Trends
                        </Link>

                        <Link
                          to={{
                            pathname: `/vendor/finance-report/${item.outletId}`,
                            state: { vendorId: vendorId },
                          }}
                          className="btn grid-btn mt-0 btn-sm steps-btn btn-btn-set"
                        >
                          Finance Report
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOutlet;
