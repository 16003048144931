import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/action";

const Sidebar = () => {
  const [isCollapse, setIscollapse] = useState({
    city: true,
  });
  const { permissions } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    setActiveMenuItem();
    // if (permissions.length === 0) {
    //   dispatch(logout());
    // }
  }, []);

  const removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };

  const setActiveMenuItem = () => {
    $(".ms-main-aside .menu-item>a").on("click", function () {
      $(this).removeAttr("href");
      var element = $(this).parent("li");
      if (element.hasClass("active")) {
        $(".menu-item").removeClass("active");
        $(".menu-collapse").addClass("collapse");
        // element.removeClass('active');
        // element.find('li').removeClass('active');
        // element.find('.collapse').slideUp();
      } else {
        element.addClass("active");
        element.find(".menu-collapse").removeClass("collapse");
        element.find(".menu-collapse").slideDown();
        // element.addClass('active');
        // element.children('.collapse').slideDown();
        // element.siblings('li').children('.collapse').slideUp();
        // element.siblings('li').removeClass('active');
        // element.siblings('li').find('li').removeClass('active');
        // element.siblings('li').find('.collapse').slideUp();
      }
    });
  };

  console.log("permissions", permissions);
  return (
    <div>
      <div
        className="ms-aside-overlay ms-overlay-left ms-toggler"
        onClick={removeoverlay}
      ></div>
      <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
      <Scrollbar
        id="ms-side-nav"
        className="side-nav fixed ms-aside-scrollable ms-aside-left"
      >
        {/* Logo */}
        <div className="logo-sn ms-d-block-lg">
          <Link className="pl-0 ml-0 text-center" to="/">
            <img src={"/assets/img/logo.png"} alt="logo" />
          </Link>
        </div>
        {/* Navigation */}
        {permissions.map((item, index) => {
          return (
            <ul
              className="accordion ms-main-aside fs-14"
              id="side-nav-accordion"
              key={index}
            >
              <li className="menu-item">
                <Link
                  to={{
                    pathname: `/${item?.modulePath}`,
                    state: {
                      ...item,
                    },
                  }}
                >
                  {" "}
                  <span>
                    <i className="material-icons fs-16">dashboard</i>
                    {item?.moduleName}
                  </span>
                </Link>
              </li>
            </ul>
          );
        })}

        <ul className="accordion ms-main-aside fs-14" id="cities-menu">
          <li className="menu-item">
            <Link
              to={{
                pathname: `/rewards`,
              }}
            >
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                {/* <i className="material-icons fs-16">reward</i> */}
                Rewards
              </span>
            </Link>
          </li>
          <li className="menu-item">
            <Link
              to={{
                pathname: `/Comission`,
              }}
            >
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                {/* <i className="material-icons fs-16">reward</i> */}
                Comission
              </span>
            </Link>
          </li>
        </ul>

        <ul className="accordion ms-main-aside fs-14" id="cities-menu">
          <li
            className="menu-item"
            onClick={() => {
              setIscollapse({
                ...isCollapse,
                city: !isCollapse.city,
              });
            }}
          >
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                Reports{" "}
              </span>
            </Link>
            <ul
              id="city"
              className={
                isCollapse.city ? "collapse menu-collapse" : "menu-collapse"
              }
              aria-labelledby="product"
              data-parent="#cities-menu"
            >
              <li>
                {" "}
                <Link to="/growth-report">Growth Report</Link>
              </li>
              <li>
                {" "}
                <Link to="/revenue-report">Revenue Report</Link>
              </li>

              <li>
                {" "}
                <Link to="/commision-report">Commission Report</Link>
              </li>

              <li>
                {" "}
                <Link to="/discount-report">Discount Report</Link>
              </li>
            </ul>
          </li>
        </ul>

        {/* <ul className="accordion ms-main-aside fs-14" id="cities-menu">
          <li
            className="menu-item"
            onClick={() => {
              setIscollapse({
                ...isCollapse,
                city: !isCollapse.city,
              });
            }}
          >
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                City Event/Attraction{" "}
              </span>
            </Link>
            <ul
              id="city"
              className={
                isCollapse.city ? "collapse menu-collapse" : "menu-collapse"
              }
              aria-labelledby="product"
              data-parent="#cities-menu"
            >
              <li>
                {" "}
                <Link to="/city-events">City Events</Link>
              </li>
              <li>
                {" "}
                <Link to="/city-attractions">City Attractions</Link>
              </li>
            </ul>
          </li>
        </ul> */}
      </Scrollbar>
    </div>
  );
};

export default Sidebar;
