import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  capitalizeFirstLetter,
  downloadExcel1,
  getPermissionDetails,
} from "../../util/fn";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import Select from "react-select";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASEURL } from "../../constant/api";

const ViewAll = (props) => {
  const { request: requestCities, response: responseCities } = useRequest();
  const { request: request, response: response } = useRequest();
  const { request: requestStatus, response: responseStatus } = useRequest();
  const [permission, setPermissions] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const location = useLocation();
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { title: "S.No." },
    { title: "Vendor Name" },
    { title: "Email Address" },
    { title: "Mobile" },
    { title: "City" },
    { title: "Facebook Profile" },
    { title: "Instagram Profile" },
    { title: "Date" },
    { title: "Rejected Reason" },
    { title: "Status" },
  ]);

  const perPage = 10;
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [page, setPage] = useState(1);
  const [city, setCity] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [startIndex, setStartIndex] = useState(1);
  const [carouselImages, setCarouselImages] = useState([]);
  const [docViewerIsOpen, setDocViewerIsOpen] = useState(false);

  useEffect(() => {
    document.title = "Registrations - Sportyfy";
    requestCities("GET", "admin/vendor/getCity");
    request(
      "GET",
      `admin/vendor/getNewRegistration?cityId=&status=&searchString=&page=${page}&per_page=${perPage}`
      // `admin/vendor/getNewRegistration`
    );
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const handleSearch = (e) => {
    e.preventDefault();
    let searchCity = city;
    let searchString = search;
    let searchStatus = status;
    if (e.target.name == "cities") {
      setCity(e.target.value);
      searchCity = e.target.value;
    } else if (e.target.name == "search") {
      setSearch(e.target.value);
      searchString = e.target.value;
    } else if (e.target.name == "status") {
      setStatus(e.target.value);
      searchStatus = e.target.value;
    }

    searchCity = searchCity ?? "";
    searchString = searchString ?? "";
    searchStatus = searchStatus ?? "";

    if (city == "") {
      toast.error("Please select city.");
    } else {
      request(
        "GET",
        `admin/vendor/getNewRegistration?cityId=${searchCity}&status=${searchStatus}&searchString=${searchString}&page=${page}&per_page=${perPage}`
      );
    }
  };

  useEffect(() => {
    if (response) {
      if (response.status) {
        setData(response.data);
        setTotalDocuments(response.totalDocument);
      }
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setPage(selected + 1);
    if (selected + 1 == 1) {
      setStartIndex(1);
    } else {
      setStartIndex(selected * perPage + 1);
    }

    request(
      "GET",
      `admin/vendor/getNewRegistration?cityId=${city}&status=${status}&searchString=${search}&page=${
        selected + 1
      }&per_page=${perPage}`
    );
  };

  const handleStatus = (id, status) => {
    if (status == "accepted") {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to accept this registration request?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept!",
      }).then(function (result) {
        if (result.value) {
          requestStatus("POST", "admin/vendor/updateRegistrationStatus", {
            registerId: id,
            status: status,
          });
        }
      });
    } else if (status == "rejected") {
      Swal.fire({
        title: "Are you sure you want to reject this registration request?",
        text: "Enter reason to reject",
        input: "text",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        showCancelButton: true,
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Yes Reject",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value) {
          const rejectReason = result.value;
          requestStatus("POST", "admin/vendor/updateRegistrationStatus", {
            registerId: id,
            status: status,
            rejectReason: rejectReason,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus.status) {
        const { id, updatedStatus } = responseStatus;
  
        toast.success("Registration status has been updated successfully.");
        async function CallWebhoook(data) {
          await axios.post(
            `https://api.quickreply.ai/webhook/company/W7CmSYhFotkieadds_c/key/BYKK7nxYmkjTCZ68p`,
            data
          );
        }
        if (updatedStatus == "accepted") {
          CallWebhoook(responseStatus.data);
        }
        const oldRecords = data;
        const indexToChange = oldRecords.findIndex((r) => r.id == id);
        oldRecords[indexToChange].status = updatedStatus;

        setData(oldRecords);
      }
    }
  }, [responseStatus]);

  const openDocLightbox = (images) => {
    let newImages = [];
    if (images && images.length > 0) {
      images.forEach((obj) => {
        newImages.push({
          src: obj,
          width: null,
          height: null,
        });
      });
    }
    setCarouselImages(newImages);
    setDocViewerIsOpen(true);
  };

  const closeDocLightbox = () => {
    setCarouselImages([]);
    setDocViewerIsOpen(false);
  };

  useEffect(() => {
    const permissionData = getPermissionDetails(location.pathname, permissions);
    if (permissionData) {
      setPermissions(permissionData);
      if (permissionData?.webAccessEdit) {
        setColumns([...columns, { title: "Action" }]);
      }
    }
  }, []);

  const DownloadExcel = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.get(
        `${
          BASEURL.PORT
        }/admin/vendor/getNewRegistration?isExcelDownload=${true}&page=${""}&per_page=${""}&cityId=${city}`,
        config
      );
      if (response?.data?.status == true) {
        let Registration = response?.data?.data?.map((obj, index) => ({
          "Sr.": index + 1,
          "Vendor Name": obj?.name,
          "Email Address": obj?.email,
          Mobile: obj?.mobile,
          City: obj?.city,
          "Facebook Profile": obj?.facebook,
          "Instagram Profile": obj?.instagram,
          Date: obj?.date,
          Status: obj?.status,
          "Rejected Reason": obj?.rejectReason,
        }));

        const dataArray = [Registration];
        const namesArray = ["Registration"];
        const headingsArray = [null, null]; // Assuming no headers for the data

        downloadExcel1(dataArray, namesArray, headingsArray);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Registrations"}
            links={[{ to: "/", name: "Home" }]}
          />
          <div className="ms-panel">
            <div className="ms-panel-header">
              <div className="row">
                <div className="col-md-2">
                  <h6>{"Registrations"}</h6>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="cities">City:</label>
                    <Select
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      name="cities"
                      onChange={(value) => {
                        setCity(value?.value);
                        request(
                          "GET",
                          `admin/vendor/getNewRegistration?cityId=${value.value}&status=${status}&searchString=${search}&page=${page}&per_page=${perPage}`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search by vendor name"
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="status">Status:</label>
                    <select
                      className="form-control"
                      id="status"
                      name="status"
                      onChange={handleSearch}
                    >
                      <option value={""}>All</option>
                      <option value={"pending"}>Pending</option>
                      <option value={"accepted"}>Accepted</option>
                      <option value={"rejected"}>Rejected</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary burron"
                    onClick={DownloadExcel}
                  >
                    Download Excel
                  </button>
                </div>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      {columns &&
                        columns.length > 0 &&
                        columns.map((obj) => <th scope="col">{obj?.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr key={item.id}>
                          <th scope="row">{i + startIndex}</th>

                          <td>
                            {item.name ? capitalizeFirstLetter(item.name) : "-"}
                          </td>
                          <td>{item?.email ? item.email : "-"}</td>
                          <td>{item?.mobile ? item.mobile : "-"}</td>
                          <td>{item?.city ? item.city : "-"}</td>
                          <td>{item.facebook ? item.facebook : "-"}</td>
                          <td>{item?.instagram ? item.instagram : "-"}</td>

                          <td>{item?.date}</td>
                          <td>{item?.rejectReason}</td>
                          <td>
                            {item?.status == "accepted" ? (
                              <span className="badge badge-success">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "rejected" ? (
                              <span className="badge badge-danger">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : item.status == "pending" ? (
                              <span className="badge badge-primary">
                                {capitalizeFirstLetter(item.status)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>

                          {permission?.webAccessEdit && (
                            <td>
                              {item.status == "pending" ? (
                                <>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleStatus(item.id, "accepted")
                                    }
                                  >
                                    <i
                                      title="Accept"
                                      className="flaticon-tick-inside-circle"
                                    ></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleStatus(item.id, "rejected")
                                    }
                                  >
                                    <i
                                      title="Reject"
                                      className="fa fa-ban ms-text-danger"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </>
                              ) : item.status == "accepted" ? (
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handleStatus(item.id, "rejected")
                                  }
                                >
                                  <i
                                    title="Reject"
                                    className="fa fa-ban ms-text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              ) : item.status == "rejected" ? (
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handleStatus(item.id, "accepted")
                                  }
                                >
                                  <i
                                    title="Accept"
                                    className="flaticon-tick-inside-circle"
                                  ></i>
                                </Link>
                              ) : (
                                ""
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    {data.length == 0 && (
                      <tr>
                        <td colSpan="10" style={{ textAlign: "center" }}>
                          {" "}
                          No registration has found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {totalDocuments > 1 && (
            <Pagination
              page={page}
              totalDocuments={totalDocuments}
              getNewData={fetchMoreData}
              perPage={perPage}
              currentDocLength={data?.length}
            />
          )}
        </div>
      </div>
      <ModalGateway>
        {docViewerIsOpen ? (
          <Modal onClose={closeDocLightbox}>
            <Carousel
              currentindex={0}
              views={carouselImages.map((x) => ({
                ...x,
                srcset: x.src,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ViewAll;
